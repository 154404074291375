import { Percent, unPercent } from './percent'

/**
 * Outcome of the vote: 'yea' (accepted) or 'nay' (rejected). In case of a tiebreaker
 * question (Stichfrage), 'yea' means the popular initiative is preferred, 'nay' means
 * the counter-proposal is preferred.
 */
export type Outcome = 'yea' | 'nay'

export const mkOutcome = (x: Outcome): Outcome => x

export interface OutcomeMatcher<T> {
  yea: T
  nay: T
}

export const caseOutcome =
  <T>(m: OutcomeMatcher<T>) =>
  (outcome: Outcome): T =>
    m[outcome]

export const outcomeFromPercent = (p: Percent): Outcome => (unPercent(p) > 0.5 ? 'yea' : 'nay')
