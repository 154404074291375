import * as React from 'react'
import * as ReactDOM from 'react-dom/client'
import { createBrowserHistory } from 'history'
import { App } from './app'

import { initializeApp } from 'firebase/app'
import * as firestore from 'firebase/firestore'

const main = async () => {
  const containerElement = document.getElementById('app')

  const firebaseApp = initializeApp(__firebaseConfig__)

  const history = createBrowserHistory()
  const db = firestore.getFirestore(firebaseApp)

  const root = ReactDOM.createRoot(containerElement!)
  root.render(<App history={history} db={db} />)
}

main().catch((err) => {
  console.error('main', err)
})
