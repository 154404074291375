import * as React from 'react'
import { History, createMemoryHistory } from 'history'
import { useIntl } from 'react-intl'
import { FirebaseApp } from 'firebase/app'
import * as firestore from 'firebase/firestore'

/**
 * Read-only environment that is made available all components in the app.
 * This object is conceptually equivalent to a global variable. But we don't
 * use global variables and instead pass it down the tree using the React
 * context.
 */
export interface Env {
  history: History

  firebaseApp: FirebaseApp
  db: firestore.Firestore
}

export const Env = React.createContext<Env>({
  history: createMemoryHistory(),

  firebaseApp: null as any,
  db: null as any,
})

export const useEnv = () => {
  return { ...React.useContext(Env), intl: useIntl() }
}
