import * as React from 'react'
import styled from '@emotion/styled'
import { parseHostname } from '../lib/parseHostname'

export const Loader = () => {
  const publicationName = parseHostname().publicationName
  const imgSrc = `https://interaktiv.tagesanzeiger.ch/static/icons/${publicationName}.svg`
  return (
    <Root>
      <img src={imgSrc} />
    </Root>
  )
}

export const CenteredLoader = () => (
  <Fill>
    <Loader />
  </Fill>
)

// ----------------------------------------------------------------------------

const Fill = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
`

const Root = styled('div')`
  width: 60px;
  height: 60px;
  animation: rotate 3s infinite ease-in-out;

  @keyframes rotate {
    0% {
      opacity: 0;
    }
    25% {
      opacity: 0;
      transform: perspective(120px) rotateX(0deg) rotateY(90deg);
    }
    50% {
      opacity: 0.8;
    }
    75% {
      transform: perspective(120px) rotateX(0deg) rotateY(-90deg);
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
`
