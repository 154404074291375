export const dashboard = '/'
import * as Sentry from '@sentry/browser'

// this returns the hostname with the correct tenant inside of embeds eg. a embed on landbote.ch should link to abstimmungen.landbote.ch
export const tenantHostName = () => {
  // don't do tenant replacement if we are on dev
  if (
    window.location.hostname.includes('tamedia-ballot-dashboard-dev') ||
    window.location.hostname.includes('localhost')
  ) {
    return ''
  }

  // https://www.landbote.ch
  const tenantUrl = window.location !== window.parent.location ? document.referrer : document.location.origin

  Sentry.withScope((scope) => {
    scope.setExtras({ referrer: document.referrer, parent: window.parent.location })
    Sentry.captureMessage('tenantHostName')
  })

  // remove last character of url if it is a slash
  const tenantUrlWithoutSlash = tenantUrl.endsWith('/') ? tenantUrl.slice(0, -1) : tenantUrl

  // landbote.ch
  const cleanedTenantURL = tenantUrlWithoutSlash.replace('https://', '').replace('www.', '')

  // [landbote, ch]
  const parentWindowURLArray = cleanedTenantURL.split('.')

  // [abstimmungen, tagesanzeiger, ch]
  const hostNameArray = window.location.hostname.split('.')

  // [abstimmungen, landbote, ch]
  hostNameArray[hostNameArray.length - 2] = parentWindowURLArray[parentWindowURLArray.length - 2] ?? 'tagesanzeiger'

  return 'https://' + hostNameArray.join('.')
}

export const popularVoteFederal = (popularVoteId: string) => `/${popularVoteId}`

export const popularVoteCantonal = (popularVoteId: string, cantonId?: string) => {
  if (cantonId) {
    return `/${popularVoteId}/c#${cantonId}`
  } else {
    return `/${popularVoteId}/c`
  }
}

export const federalBallot = (popularVoteId: string, federalBallotId: string) => `/${popularVoteId}/${federalBallotId}`

export const federalBallotMunicipalities = (popularVoteId: string, federalBallotId: string) =>
  `/${popularVoteId}/${federalBallotId}/m`

export const cantonalBallot = (popularVoteId: string, cantonId: string, ballotId: string) =>
  `/${popularVoteId}/${cantonId}/${ballotId}`

export const cantonalBallotMunicipalities = (popularVoteId: string, cantonId: string, ballotId: string) =>
  `/${popularVoteId}/${cantonId}/${ballotId}/m`
