/**
 * In reality there are more than these two ballot variants. But in this codebase
 * we only distinguish between two: a regular ballot, and a tiebreak vote.
 *
 * - default: People vote yes/no to accept or reject a ballot.
 * - tieBreak: To decide between a ballot and its counterproposal in case both are accepted.
 *
 * Tiebreak ballots may be possible on national level, but are pretty regularly observed
 * in cantonal ballots.
 *
 * The UI uses different labels and colors to display these variants. For example,
 * the default ballot variant uses a red/blue diverding color scale, but tiebreak
 * ballots use purple/green (a different color scale).
 *
 * When possible use 'ballotVariant' for the variable name, argument, object field etc
 * that holds values of this type.
 */
export type BallotVariant = 'default' | 'tieBreak'

export interface BallotVariantMatcher<T> {
  default: T
  tieBreak: T
}

export function caseBallotVariant<T>(ballotVariant: BallotVariant, m: BallotVariantMatcher<T>): T {
  return m[ballotVariant]
}
