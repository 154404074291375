export const grey = {
  '50': '#FAFAFA',
  '100': '#F5F5F5',
  '200': '#EAEAEA',
  '300': '#DDDDDD',
  '400': '#CCCCCC',
  '500': '#9E9E9E',
  '600': '#757575',
  '700': '#616161',
  '800': '#424242',
  '900': '#212121',
}

export const red = {
  '50': '#FBEBEC',
  '100': '#F5D1D3',
  '200': '#EFB7BA',
  '300': '#EA9CA2',
  '400': '#E48289',
  '500': '#DE6870',
  '600': '#D94E58',
  '700': '#D3343F',
  '800': '#9E272F',
  '900': '#6A1A20',
}

export const green = {
  '50': '#FFFFFF',
  '100': '#FFFFFF',
  '200': '#E6F5D0',
  '300': '#FFFFFF',
  '400': '#B8E186',
  '500': '#7FBC41',
  '600': '#FFFFFF',
  '700': '#4D9221',
  '800': '#276419',
  '900': '#FFFFFF',
}

export const purple = {
  '50': '#FFFFFF',
  '100': '#FFFFFF',
  '200': '#FDE0EF',
  '300': '#FFFFFF',
  '400': '#F1B6DA',
  '500': '#DE77AE',
  '600': '#FFFFFF',
  '700': '#C51B7D',
  '800': '#8E0152',
  '900': '#FFFFFF',
}

// https://hihayk.github.io/scale/#2/7/50/90/0/0/0/-0/1190D7/17/144/215
export const blue = {
  '50': '#EBF4F8',
  '100': '#D1E5F0',
  '200': '#B8D7E7',
  '300': '#9EC8DF',
  '400': '#84BAD7',
  '500': '#6AABCE',
  '600': '#519DC6',
  '700': '#378EBD',
  '800': '#296B8E',
  '900': '#1C475F',
}

export const divergeA = {
  '50': '#EEEBF3',
  '100': '#D8D1E2',
  '200': '#C2B7D2',
  '300': '#AC9CC2',
  '400': '#9682B2',
  '500': '#8068A2',
  '600': '#6A4E92',
  '700': '#543482',
  '800': '#3F2762',
  '900': '#2A1A41',
}

export const divergeB = {
  '50': '#ECF2F1',
  '100': '#D3E2DE',
  '200': '#BAD2CC',
  '300': '#A1C1B9',
  '400': '#88B1A7',
  '500': '#70A194',
  '600': '#579082',
  '700': '#3E806F',
  '800': '#2F6053',
  '900': '#1F4038',
}
